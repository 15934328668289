const grays = {
  white: `#fff`,
  gray0: `#f7f7f7`,
  gray1: `#ededed`,
  gray2: `#e3e3e3`,
  gray3: `#cfcfcf`,
  gray4: `#8C8C88`,
  gray5: `#686865`,
  gray6: `#535353`,
  gray7: `#404040`,
  gray8: `#303030`,
  gray9: `#1f1f1f`,
  black: `#000`,
}

const slates = {
  slate0: `#f3f8fc`,
  slate1: `#edf2f7`,
  slate2: `#e3e8ed`,
  slate3: `#c8d1da`,
  slate4: `#a3b3c4`,
  slate5: `#909ead`,
  slate6: `#364453`,
  slate7: `#2b3946`,
  slate8: `#1f2a36`,
  slate9: `#131e29`,
}

const blues = {
  blue3: `#02a1fe`,
  blue4: `#1366a6`,
  blue7: `#25314c`,
}

const greens = {
  green1: `#65dcb7`,
}

const grids = {
  lightGrid: `rgba(2, 161, 254, 0.125)`,
  grid: `rgba(2, 161, 254, 0.25)`,
  gridDark: greens.green1,
}

const miscellaneous = {
  white_black: grays.white,
  black_white: grays.black,
  blue7_slate1: blues.blue7,
  white_slate7: grays.white,
  gray6_slate3: grays.gray6,
  gray5_slate4: grays.gray5,
  gray4_slate5: grays.gray4,
  gray2_slate9: grays.gray2,
  gray1_slate9: grays.gray1,
  white_slate8: grays.white,
  gray1_slate8: grays.gray1,
  gray1_black: grays.gray1,
  gray0_slate8: grays.gray0,
  gray0_slate9: grays.gray0,
  gray0_black: grays.gray0,
  transparent_black: `transparent`,
  gray6_c1cdda: grays.gray6,
  card: `rgba(255, 255, 255, 0.75)`,
  underlay: `rgba(247, 247, 247, 0.75)`,
  quoteUnderlay: `rgba(255, 255, 255, 0.75)`,
  white_0b1218: grays.white,
  border: `rgba(0, 0, 0, 0.125)`,
  avatarShadow: `rgba(0, 0, 0, 0.15)`,
  text: grays.gray9,
  background: grays.gray0,
  primary: `#035697`,
  cartaXLogoPrimary: `#25314c`,
  cartaXLogoSecondary: blues.blue7,
  goldmanSachsLogoPrimary: `#7399c6`,
  goldmanSachsLogoSecondary: grays.white,
  xPrimary: `#02a1fe`,
  xSecondary: `#25314c`,
  xTertiary: grays.black,
  xDimmedPrimary: `#e3eff8`,
  xDimmedSecondary: `#e3e4e6`,
  xDimmedTertiary: `rgba(0, 0, 0, 0.1)`,
}

const miscellaneousDark = {
  white_black: grays.black,
  black_white: grays.white,
  blue7_slate1: slates.slate1,
  white_slate7: slates.slate7,
  gray6_slate3: slates.slate3,
  gray5_slate4: slates.slate4,
  gray4_slate5: slates.slate5,
  gray2_slate9: slates.slate9,
  gray1_slate9: slates.slate9,
  white_slate8: slates.slate8,
  gray1_slate8: slates.slate8,
  gray1_black: grays.black,
  gray0_slate8: slates.slate8,
  gray0_slate9: slates.slate9,
  gray0_black: grays.black,
  transparent_black: grays.black,
  gray6_c1cdda: `#c1cdda`,
  card: `rgba(3, 5, 6, 0.75)`,
  underlay: `rgba(19, 30, 41, 0.75)`,
  quoteUnderlay: `rgba(11, 18, 24, 0.6)`,
  white_0b1218: `#0b1218`,
  border: `rgba(255, 255, 255, 0.125)`,
  avatarShadow: `rgba(0, 0, 0, 0.35)`,
  grid: grids.gridDark,
  text: slates.slate0,
  background: slates.slate9,
  primary: `#41cbc8`,
  cartaXLogoPrimary: grays.white,
  cartaXLogoSecondary: blues.blue7,
  goldmanSachsLogoPrimary: grays.white,
  goldmanSachsLogoSecondary: `#0b1218`,
  xPrimary: `#02a1fe`,
  xSecondary: `#fff`,
  xTertiary: miscellaneous.xTertiary,
  xDimmedPrimary: `#061b2d`,
  xDimmedSecondary: `#2c2c2c`,
  xDimmedTertiary: miscellaneous.xDimmedTertiary,
}

export default {
  ...grays,
  ...slates,
  ...blues,
  ...greens,
  ...grids,
  ...miscellaneous,
  modes: {
    dark: {
      ...miscellaneousDark,
    },
  },
}
