// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-contentful-blog-post-slug-tsx": () => import("./../../../src/pages/blog/{ContentfulBlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-brand-tsx": () => import("./../../../src/pages/brand.tsx" /* webpackChunkName: "component---src-pages-brand-tsx" */),
  "component---src-pages-case-studies-contentful-case-study-slug-tsx": () => import("./../../../src/pages/case-studies/{ContentfulCaseStudy.slug}.tsx" /* webpackChunkName: "component---src-pages-case-studies-contentful-case-study-slug-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-content-center-index-tsx": () => import("./../../../src/pages/content-center/index.tsx" /* webpackChunkName: "component---src-pages-content-center-index-tsx" */),
  "component---src-pages-content-center-tags-contentful-tag-slug-tsx": () => import("./../../../src/pages/content-center/tags/{ContentfulTag.slug}.tsx" /* webpackChunkName: "component---src-pages-content-center-tags-contentful-tag-slug-tsx" */),
  "component---src-pages-content-center-tags-index-tsx": () => import("./../../../src/pages/content-center/tags/index.tsx" /* webpackChunkName: "component---src-pages-content-center-tags-index-tsx" */),
  "component---src-pages-documents-buyers-guide-tsx": () => import("./../../../src/pages/documents/buyers-guide.tsx" /* webpackChunkName: "component---src-pages-documents-buyers-guide-tsx" */),
  "component---src-pages-documents-carta-cross-buyers-guide-tsx": () => import("./../../../src/pages/documents/carta-cross-buyers-guide.tsx" /* webpackChunkName: "component---src-pages-documents-carta-cross-buyers-guide-tsx" */),
  "component---src-pages-documents-carta-cross-sellers-guide-tsx": () => import("./../../../src/pages/documents/carta-cross-sellers-guide.tsx" /* webpackChunkName: "component---src-pages-documents-carta-cross-sellers-guide-tsx" */),
  "component---src-pages-documents-index-tsx": () => import("./../../../src/pages/documents/index.tsx" /* webpackChunkName: "component---src-pages-documents-index-tsx" */),
  "component---src-pages-documents-sellers-guide-tsx": () => import("./../../../src/pages/documents/sellers-guide.tsx" /* webpackChunkName: "component---src-pages-documents-sellers-guide-tsx" */),
  "component---src-pages-documents-tax-guide-for-sellers-tsx": () => import("./../../../src/pages/documents/tax-guide-for-sellers.tsx" /* webpackChunkName: "component---src-pages-documents-tax-guide-for-sellers-tsx" */),
  "component---src-pages-documents-tender-offer-buyers-guide-tsx": () => import("./../../../src/pages/documents/tender-offer-buyers-guide.tsx" /* webpackChunkName: "component---src-pages-documents-tender-offer-buyers-guide-tsx" */),
  "component---src-pages-documents-tender-offer-sellers-guide-tsx": () => import("./../../../src/pages/documents/tender-offer-sellers-guide.tsx" /* webpackChunkName: "component---src-pages-documents-tender-offer-sellers-guide-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../../../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-institutional-investors-index-tsx": () => import("./../../../src/pages/institutional-investors/index.tsx" /* webpackChunkName: "component---src-pages-institutional-investors-index-tsx" */),
  "component---src-pages-institutional-investors-request-information-tsx": () => import("./../../../src/pages/institutional-investors/request-information.tsx" /* webpackChunkName: "component---src-pages-institutional-investors-request-information-tsx" */),
  "component---src-pages-institutional-investors-thank-you-tsx": () => import("./../../../src/pages/institutional-investors/thank-you.tsx" /* webpackChunkName: "component---src-pages-institutional-investors-thank-you-tsx" */),
  "component---src-pages-investor-application-documents-brokerage-account-agreement-tsx": () => import("./../../../src/pages/investor-application-documents/brokerage-account-agreement.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-brokerage-account-agreement-tsx" */),
  "component---src-pages-investor-application-documents-certificate-of-authority-tsx": () => import("./../../../src/pages/investor-application-documents/certificate-of-authority.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-certificate-of-authority-tsx" */),
  "component---src-pages-investor-application-documents-index-tsx": () => import("./../../../src/pages/investor-application-documents/index.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-index-tsx" */),
  "component---src-pages-investor-application-documents-individual-accredited-investor-questionnaire-tsx": () => import("./../../../src/pages/investor-application-documents/individual-accredited-investor-questionnaire.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-individual-accredited-investor-questionnaire-tsx" */),
  "component---src-pages-investor-application-documents-investor-application-form-tsx": () => import("./../../../src/pages/investor-application-documents/investor-application-form.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-investor-application-form-tsx" */),
  "component---src-pages-investor-application-documents-investor-application-section-2-tsx": () => import("./../../../src/pages/investor-application-documents/investor-application-section-2.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-investor-application-section-2-tsx" */),
  "component---src-pages-investor-application-documents-investor-application-section-3-tsx": () => import("./../../../src/pages/investor-application-documents/investor-application-section-3.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-investor-application-section-3-tsx" */),
  "component---src-pages-investor-application-documents-investor-application-section-4-tsx": () => import("./../../../src/pages/investor-application-documents/investor-application-section-4.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-investor-application-section-4-tsx" */),
  "component---src-pages-investor-application-documents-investor-application-section-5-tsx": () => import("./../../../src/pages/investor-application-documents/investor-application-section-5.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-investor-application-section-5-tsx" */),
  "component---src-pages-investor-application-documents-investor-application-section-6-tsx": () => import("./../../../src/pages/investor-application-documents/investor-application-section-6.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-investor-application-section-6-tsx" */),
  "component---src-pages-investor-application-documents-qib-questionnaire-tsx": () => import("./../../../src/pages/investor-application-documents/qib-questionnaire.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-qib-questionnaire-tsx" */),
  "component---src-pages-investor-application-documents-suitability-attestation-tsx": () => import("./../../../src/pages/investor-application-documents/suitability-attestation.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-suitability-attestation-tsx" */),
  "component---src-pages-investor-application-documents-third-party-agent-authorization-tsx": () => import("./../../../src/pages/investor-application-documents/third-party-agent-authorization.tsx" /* webpackChunkName: "component---src-pages-investor-application-documents-third-party-agent-authorization-tsx" */),
  "component---src-pages-partners-academic-tsx": () => import("./../../../src/pages/partners/academic.tsx" /* webpackChunkName: "component---src-pages-partners-academic-tsx" */),
  "component---src-pages-partners-legal-tsx": () => import("./../../../src/pages/partners/legal.tsx" /* webpackChunkName: "component---src-pages-partners-legal-tsx" */),
  "component---src-pages-private-companies-carta-cross-tsx": () => import("./../../../src/pages/private-companies/carta-cross.tsx" /* webpackChunkName: "component---src-pages-private-companies-carta-cross-tsx" */),
  "component---src-pages-private-companies-index-tsx": () => import("./../../../src/pages/private-companies/index.tsx" /* webpackChunkName: "component---src-pages-private-companies-index-tsx" */),
  "component---src-pages-private-companies-request-information-tsx": () => import("./../../../src/pages/private-companies/request-information.tsx" /* webpackChunkName: "component---src-pages-private-companies-request-information-tsx" */),
  "component---src-pages-private-companies-thank-you-tsx": () => import("./../../../src/pages/private-companies/thank-you.tsx" /* webpackChunkName: "component---src-pages-private-companies-thank-you-tsx" */),
  "component---src-pages-regulatory-disclosures-tsx": () => import("./../../../src/pages/regulatory-disclosures.tsx" /* webpackChunkName: "component---src-pages-regulatory-disclosures-tsx" */),
  "component---src-pages-request-information-tsx": () => import("./../../../src/pages/request-information.tsx" /* webpackChunkName: "component---src-pages-request-information-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

